export const OVERRIDE_COOKIE = 'toggle-overrides';
export const CONSUMER_TAG = 'new-stack-web';

export enum FEATURES {
  SECTION_SIGNUP = 'SECTION_SIGNUP',

  /**
   * Allow users to configure recurring payment plans.
   */
  MOBILE_PAYMENT_UI_FLOW_V1 = 'mobile-payment-plan-flow-v1',

  /**
   * Direct users to the new mobile payment flow that enables bitcoin payments
   */
  MOBILE_PAYMENT_UI_FLOW_V2 = 'mobile-payment-ui-flow-v2',

  /**
   * New NBN Manage Dashboard https://belongranda.atlassian.net/browse/AMF-772
   */
  INTERNET_DASHBOARD = 'new-stack-manage-internet-page',

  /**
   * PO Box address lookup
   * https://belongranda.atlassian.net/browse/ACES-78
   */
  ALLOW_PO_BOX_LOOKUP_FEATURE = 'po-box-address-look-up',

  /**
   * Live Chat
   * https://belongranda.atlassian.net/browse/AMF-1368
   */
  LIVE_CHAT = 'live-chat',

  /**
   * Live Chat
   * https://belongranda.atlassian.net/browse/PATH-2424
   */
  LIVE_CHAT_IFRAME = 'live-chat-iframe',

  /**
   * Live Chat as a separate app
   * https://belongranda.atlassian.net/browse/AMF-2442
   */
  LIVE_CHAT_SEPARATE_APP = 'live-chat-separate-app',

  /**
   * Live Chat attachments
   * https://belongranda.atlassian.net/browse/FLEX-82
   */
  LIVE_CHAT_ATTACHMENTS = 'live-chat-attachments',

  /**
   * NBN Manual Address V2 Form for Modem Delivery Page
   * https://belongranda.atlassian.net/browse/FB-992
   */
  NBN_MANUAL_DELIVERY_ADDRESS_V2 = 'nbn-manual-delivery-address-v2',

  /**
   * NBN Future Dated Order for Connection Date Page
   * https://belongranda.atlassian.net/browse/FB-1729 1730
   */
  NBN_FUTURE_DATED_ORDER = 'nbn-future-dated-order',

  /**
   * Uplift for FTTP Service Class 3
   * https://belongranda.atlassian.net/browse/AMF-2450
   */
  SERVICE_TRANSFER_EXTERNAL_GAIN = 'service-transfer-external-gain',

  /**
   * nbn technician appointments
   * https://belongranda.atlassian.net/browse/AMF-140
   */
  NBN_APPOINTMENT = 'nbn-appointment',

  /**
   * nbn services summary
   * https://belongranda.atlassian.net/browse/AMF-2732
   */
  NBN_SERVICES_SUMMARY = 'nbn-services-summary',

  /**
   * Payment assistance application form
   * https://belongranda.atlassian.net/browse/AMF-2753
   */
  PAYMENT_ASSISTANCE = 'payment-assistance',

  /**
   * ID verification
   * https://belongranda.atlassian.net/browse/AMF-2889
   */
  ID_VERIFICATION = 'id-verification-ui',

  /**
   * eSIM
   * https://belongranda.atlassian.net/browse/EF-2
   */
  ESIM = 'esim',

  /**
   * nbn service transfer
   * https://belongranda.atlassian.net/browse/PLF-65
   * validate avcid as part of the nbn purchase flow
   */

  VALIDATE_AVCID = 'nbn-service-transfer-validate-avcid',

  /**
   * Use activate sim domain, rather than the myaccount version
   * https://belongranda.atlassian.net/browse/FP-311
   */
  ACTIVATE_SIM = 'activate-sim-domain',

  /**
   * Order Summary AB test
   * https://belongranda.atlassian.net/browse/DEF-3
   */
  ORDER_SUMMARY_AB_TEST = 'order-summary-ab-test',

  /**
   * Order Summary AB test
   * https://belongranda.atlassian.net/browse/DEF-3
   */
  REMOVE_UNNECESSARY_SCRIPT_PAYMENT_PAGE = 'remove-unnecessary-script-payment-page',
  /**
   * NBN SQ Uplift
   * https://belongranda.atlassian.net/browse/DEF-9
   */
  NBN_SQ_UPLIFT = 'sq-nbn-uplift'
}

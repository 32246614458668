import { useCallback, useContext } from 'react';

import { FEATURES } from '../constants';
import { FeatureTogglesContext } from '../components';
import type { IFeatureToggles } from '../types';

interface IUseFeatureToggles {
  isFeatureOn(feature: `${FEATURES}`): boolean;
  features: IFeatureToggles;
}

export const useFeatureToggles = (): IUseFeatureToggles => {
  const features = useContext(FeatureTogglesContext);

  if (!features) {
    Error('useFeatureToggles must be used within a <FeatureTogglesProvider>');
  }

  const isFeatureOn = useCallback((feature: string) => features[feature] ?? false, [features]);

  return {
    isFeatureOn,
    features
  };
};

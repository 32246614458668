import Link from 'next/link';
import { useRouter } from 'next/compat/router';
import {
  isExternalUrl,
  isKnownRoute,
  isAnchor,
  matchRoute,
  withoutBasePath,
  withBasePath,
  isPaymentUrl,
  withoutParams
} from '@belong/url-utils';
import { IOptionalStringMap, IStringMap, removeObjectEmptyProps } from '@belong/utils';
import RouteConfig from '@belong/configs/routes';
import { ILinkRenderer } from '@belong/types';
import { FEATURES, useFeatureToggles } from '@belong/feature-toggles';
import { getConfig as nextConfig } from '@belong/configs/next/config';
import { compileLinkProps, makeHrefFromLinkProps } from './utils';

// BUTTON - No HREF provided
export const button: ILinkRenderer = {
  name: 'button',
  test({ href }): boolean {
    return !href;
  },
  render(Component, props, _): JSX.Element {
    const { href, asUrl, query, ...otherProps } = props;
    return <Component {...otherProps} />;
  }
};

/**
 * INTERNAL - matches known app routes
 * Formats url and params and renders an internal link using the NextJS Link component.
 * https://nextjs.org/docs/api-reference/next/link
 *
 * Features:
 * - populates tokens (:id) in destination url using values from the provided query
 * - populates tokens (:id) in destination url using values from current url if not found in the query
 * - removes any tokens used to create url from the query
 * - replaces known tokens in url params. Currently only 'page' - e.g. my-path?from={{page}}
 * - combines any passed query with any query on the asUrl and then adds them back to the url together
 * - removes the basePath from the path for links served by this app
 * */

const SameOriginLink = (Component, props, config): JSX.Element => {
  const { href, query, ...otherProps } = props;
  const isHrefPaymentUrl = isPaymentUrl(withoutParams(href));
  const router = useRouter();
  const currentPath = router ? router.asPath : '';
  const { basePath } = nextConfig().publicRuntimeConfig;
  const dictionary = { page: currentPath.split('/').pop() };
  const matchedRoute = matchRoute(currentPath, config.routes || []);
  const urlParams: IOptionalStringMap = {
    ...(matchedRoute?.match ?? {}),
    ...(removeObjectEmptyProps(query) as IStringMap)
  };
  const { isFeatureOn } = useFeatureToggles();

  const isCurrentUrlPaymentPage = isPaymentUrl(withBasePath(matchedRoute?.pathname ?? ''));
  const shouldRenderSSRLinkPDP =
    (isHrefPaymentUrl || isCurrentUrlPaymentPage) && isFeatureOn(FEATURES.REMOVE_UNNECESSARY_SCRIPT_PAYMENT_PAGE);

  const linkProps = compileLinkProps({
    href,
    currentPath,
    dictionary,
    urlParams,
    query
  });

  const canSPATransitionToNextURL = isKnownRoute(href, undefined, config.routes);

  // clean up href if necessary
  if (canSPATransitionToNextURL) {
    if (linkProps.href?.pathname) {
      linkProps.href.pathname = withoutBasePath(linkProps.href.pathname, basePath);
    }
  }

  if (isAnchor(linkProps.href.toString()) && linkProps.href.pathname === currentPath) {
    delete linkProps.href.pathname;
  }

  /**
   * If the know the URL is served by this app, use the NextJS Link component.
   * It will create a nice SPA transition.
   *
   * Links to URLs that are not served by this app (eg myaccount urls from the public app)
   * cannot use the NextJS Link component. Instead we need to use a standard anchor tag.
   *
   * @see https://nextjs.org/learn/basics/navigate-between-pages/client-side
   */
  // destinationHref is needed when rendering an achor tag
  const destinationHref = makeHrefFromLinkProps(linkProps);

  if (canSPATransitionToNextURL) {
    // For PDP pages, we would like a hard refresh for same app route which need to add base path back.

    if (shouldRenderSSRLinkPDP) {
      return (
        <Component as="a" {...otherProps} href={withBasePath(destinationHref)}>
          {otherProps.children}
        </Component>
      );
    }

    // For non PDP pages, we use client side routing, so the url will be the without base path one.
    return (
      <Link {...linkProps} passHref legacyBehavior>
        <Component as="a" {...otherProps} />
      </Link>
    );
  }

  return (
    <Component as="a" {...otherProps} href={destinationHref}>
      {otherProps.children}
    </Component>
  );
};

// special links like mailto:xxx, tel:xxx, sms:xxxx
export const specialLink: ILinkRenderer = {
  name: 'specialLink',
  test({ href }): boolean {
    return /^(mailto|tel|sms|geo):/.test(href || '');
  },
  render(Component, props): JSX.Element {
    const { href, asUrl, query, ...otherProps } = props;

    return <Component as="a" href={asUrl || href} {...otherProps} />;
  }
};

// EXTERNAL - url is fully qualified
export const externalLink: ILinkRenderer = {
  name: 'external',
  test({ href }): boolean {
    return isExternalUrl(href);
  },
  render(Component, props): JSX.Element {
    const { href, asUrl, query, ...otherProps } = props;

    return <Component as="a" href={href} {...otherProps} />;
  }
};

export const sameOriginLink: ILinkRenderer = {
  name: 'internal',
  test({ href }): boolean {
    return isAnchor(href) || !isExternalUrl(href);
  },
  render: SameOriginLink
};

SameOriginLink.displayName = 'SameOriginLink';

export const defaultRenderer = button;

export const config = new RouteConfig();
config.setLinkRenderers(button, externalLink, specialLink, sameOriginLink);
config.defaultLinkRenderer = defaultRenderer;

import { logger } from '@belong/logging/logger';
import { IRouteDefinition, IRouteMatch } from '@belong/types';
import queryString from 'querystring';
import urlParse from 'url-parse';
import UrlPattern from 'url-pattern';
import Router from 'next/router';
import { ServerResponse } from 'http';
import { NextPageContext } from 'next';
import { getConfig } from '@belong/configs/next/config';
import { isBrowser } from '@belong/env';
import { IOptionalStringMap, IStringMap, replaceAll, removeObjectEmptyProps } from '@belong/utils';

export const isBlockedPage = (path: string, pages: Record<string, boolean>): boolean => {
  return path in pages && pages[path];
};

export const isPDF = (href = ''): boolean => /\.pdf$/i.test(urlParse(href).pathname);

// Next Router does not allow us to pushRoute to external links
// For external links, we need to use window.location.
// Function to check if url's are external links or different domain links.
export const isExternalUrl = (url = ''): boolean => {
  // These links trigger custom actions when the web page is loaded
  // inside a webview on our native app.
  // eg: belong://navigate/notifications/218211 will make the app show the notification details for notification #218211
  if (url.startsWith('belong://')) {
    return true;
  }

  return /^(https?:)?[/]{2}/.test(url);
};
export const isRelativeUrl = (url = ''): boolean => /^(\.?\/(?![/])|#)/.test(url);
export const isAnchor = (url = ''): boolean => /^#/.test(url);
export const isErrorPage = (url = ''): boolean => /^(\/[^/]+)?\/(404|500|_error)$/.test(url);
export const is404Page = (url = ''): boolean => /\/404$/.test(url);

export enum SERVICE_URLS {
  ACTIVE_SIM = '/new/services',
  ORDER_SIM = '/new/order-sim'
}

export const addParamsToUrl = (url: string, query: IOptionalStringMap): string => {
  const urlParams = url.includes('?') ? url.split('?').pop() : null;
  const params = removeObjectEmptyProps({
    ...queryString.parse(urlParams || ''),
    ...query
  });

  return `${url.split('?').shift()}?${queryString.stringify(params)}`;
};

/**
 * Replace named tokens in a url using values provided
 *
 * @example
 * ```javascript
 * compile('google.com/:foo/:bar', { foo: 'hello', bar: 'world' })
 * // --> 'google.com/hello/world'
 * ```
 */
export const compile = (urlTemplate: string, values: IOptionalStringMap): string =>
  Object.entries(values).reduce((acc, [key, value]) => {
    return acc.replace(`:${key}`, (value ?? '').toString());
  }, urlTemplate);

/**
 * Extract named values from a url using known route patterns
 *
 * @example
 * getUrlValues([{path: 'google.com/:foo/:bar', pattern: UrlPattern}], 'google.com/hello/world')
 * // --> { foo: 'hello', bar: 'world' }
 */
export const getUrlValues = (routes: IRouteDefinition[], url = ''): IStringMap => {
  return matchRoute(url, routes || [])?.match ?? {};
};

// Create a pattern object for a known App route
export const getRoutePattern = (route: string): IRouteDefinition => ({
  path: route,
  pattern: new UrlPattern(route)
});

// extract the path from a url
export const getUrlPath = (url = ''): string => urlParse(url).pathname;

// extract the document/page name from a url
export const getUrlFilename = (url = ''): string => urlParse(url).pathname.split('/').pop() || '';

//  extract the last nth segment from a useRouter pathName, etc: input: /internet/network-connection-device/hfc, Get last 2nd segment output: /network-connection-device/hfc
export const getLastNthSegments = (url = '', n = 1): string => {
  const { pathname } = urlParse(url);
  const segments = pathname.split('/').filter(Boolean);
  if (segments.length < n) {
    logger.warn('n parameter in getLastNthSegments is bigger than pathName segments length');
    return '';
  }
  return `/${segments.slice(-n).join('/')}`;
};
// convert a full url to a root-relative one by removing protocol and host
export const getRootRelativeUrl = (url?: string): string => (url || '').replace(/^.*\/{2}[^/]+/, '');

export const withBasePath = (path?: string): string => {
  const { basePath } = getConfig().publicRuntimeConfig;

  if (!path) {
    return basePath;
  }

  if (path.startsWith(basePath)) {
    return path;
  }

  return replaceAll(`${basePath}/${path}`, '//', '/').replace(/\/$/, '');
};

// remove the first segment or specified basePath from an internal url path
export const withoutBasePath = (path?: string, basePath?: string): string => {
  let effectiveBasePath = basePath;
  if (!basePath) {
    effectiveBasePath = getConfig().publicRuntimeConfig.basePath;
  }
  const regexp = new RegExp(`^${effectiveBasePath || '/[^/#?]+'}/?`);
  return (path ?? '').replace(regexp, '/');
};

// removes param values, if present, appended to a path, e.g. /support?anything=1 => /support
export const withoutParams = (path: string): string => {
  return path.split('?').shift() ?? '';
};

// If within the current app, do a fancy SPA routing, otherwise full page load
export const redirectTo = (route: string): ReturnType<typeof Router.push> | void => {
  const { basePath } = getConfig().publicRuntimeConfig;
  return route.startsWith(basePath) ? Router.push(withoutBasePath(route)) : navigateToExternalPage(route);
};

/**
 * Check if url is a known route.
 * Compares tokenized and non-tokenized urls
 *
 * Notes:
 * This also protects against paths that may start with the app's basePath, but aren't actually handled by the app
 * For example: /support/mobile (new-stack) - /support/mobile/getting-started (classic)
 *
 * Also caters for app paths that do NOT start with the basePath
 * For example: /login
 *
 * If the url contains tokens, it won't match against the route patterns,
 * so we can compile the url to replace the tokens with an 'x' char it can be validated
 */
export const isKnownRoute = (href = '', asUrl = '', routes: IRouteDefinition[]): boolean =>
  routes.some(route => {
    // remove hash and search before comparisons
    const cleanedHref = href.replace(/[?#].*$/, '');
    const cleanedAsUrl = asUrl.replace(/[?#].*$/, '');

    // check for un-compiled pattern match
    if (route.path === cleanedHref || route.path === cleanedAsUrl) {
      return true;
    }

    // compile given route and then try to match it against known patterns
    const { names } = route.pattern as any;
    const values = names.reduce((acc, name) => ({ ...acc, [name]: 'x' }), {});
    const compiledUrl = compile(cleanedAsUrl || cleanedHref, values);
    return Boolean(route.pattern.match(href) || route.pattern.match(compiledUrl));
  });

/**
 * TLRD: find dynamic route pattern from actual example and predefined routes
 *
 * The `matchRoute` function takes a URL `path` and an array of `IRouteDefinition` objects
 * and finds a matching route. If a match is found, it returns an `IRouteMatch` object
 * with properties derived from the matched route. If no match is
 * found, it returns null.
 *
 * The function can handle dynamic path segments like "/new/test/:id" and wildcards like
 * "/new/wild/*". It also ignores search parameters and hash fragments in the `path`.
 *
 * @param path The URL path to match against the routes.
 * @param routes An array of route definitions.
 * @returns A matching route object or null.
 */
export const matchRoute = (path = '', routes: IRouteDefinition[]): IRouteMatch | null => {
  // remove hash and search before comparisons
  const cleanedPath = path.replace(/[?#].*$/, '');
  const route = routes.find(({ pattern }) => !!pattern.match(cleanedPath));
  if (!route) {
    return null;
  }

  const match = route.pattern.match(cleanedPath);
  return {
    pathname: route.path.replace('*', match._),
    spec: route.path,
    match
  };
};

export const redirectInProgress = (currentUrl: string): boolean =>
  new URLSearchParams(currentUrl.split('?').pop()).has('redirect');

export const getSanitisedRedirectUrl = (url?: string): string => {
  return isRelativeUrl(url) ? url ?? '/' : '/';
};

export const serverRedirect = (res: ServerResponse, url: string, status?: number): void => {
  res.writeHead(status || 302, { Location: url }).end();
};

/**
 * Some query params might be repeated in a URL. That is why sometimes the value
 * of a query param is of type string[] instead of string.
 * This method returns the latest value of that array if there are repeated params.
 * This means we guarantee the type is always `string | undefined`.
 * @param query
 *   The query parameter NextJS router.query
 * @param paramName
 *   The name of the parameter to read value for from the query object.
 */
export const stringValueFromQuery = (
  query: Record<string, string | string[] | undefined>,
  paramName: string
): string | undefined => {
  const param = query[paramName];
  if (Array.isArray(param)) {
    return param[param.length - 1];
  }
  return param;
};

/**
 * Returns the full URL from server side or client side
 * @param ctx
 *   The nextjs context object
 */
export const getFullServerOrClientUrl = (ctx: NextPageContext): string => {
  let url = '';

  if (isBrowser()) {
    url = window.location.href;
  } else if (ctx.req?.url && ctx.req?.headers?.host) {
    const protocol = getConfig().publicRuntimeConfig.env.isDevelopment ? 'http' : 'https';
    const pagePath = protocol === 'http' && ctx.req?.url?.endsWith('.json') ? '/' : withBasePath(ctx.req?.url);
    return `${protocol}://${ctx.req.headers.host}${pagePath}`;
  }

  return url;
};

export const navigateToExternalPage = (url: string): void => window.location.assign(url);

export const isPaymentUrl = (url: string): boolean => paymentUrls.includes(url);

export const urls = {
  APP_STORE_IOS: 'https://apps.apple.com/au/app/belong/id929905619',
  APP_STORE_ANDROID: 'https://play.google.com/store/apps/details?id=com.belong',
  LOGIN: '/new/login',
  MY_PROFILE: '/new/profile',
  MY_SERVICES: '/new/services',
  SECOND_LIFE_DEVICES: 'https://shop.belong.com.au',
  SUPPORT: '/support',
  BLOG: '/go/blog',
  HOME: '/',
  NBN_PLANS: '/go/internet',
  MOBILE_DATA_PLANS: '/go/data-plans',
  MOBILE_PLANS: '/go/mobile',
  INTERNATIONAL_ROAMING: '/go/international-roaming',
  OUR_MOBILE_NETWORK: '/go/our-mobile-network',
  COVERAGE_MAP: '/go/mobile/coverage-map',
  NBN_SERVICE_QUALIFICATION: '/go/internet/check-your-address',
  AGENT_AUTHENTICATION: '/partner',
  SUSTAINABILITY: '/go/sustainability',
  SEARCH: '/go/search'
};

export const paymentUrls = [
  '/new/activate/data/:orderId/add-payment-method',
  '/new/activate/mobile/:orderId/add-payment-method',
  '/payments/:token',
  '/new/services/:serviceId/update-payment',
  '/services/broadband/:serviceId/update-payment'
];

export enum ADDRESS_API_ENDPOINTS {
  RETRIVE_TELSTRA_ADDRESS = '/address/v1/telstra/address',
  BUILDING_TYPES = '/address/v1/telstra/building-types',
  STREET_TYPES = '/address/v1/telstra/street-types'
}

export enum AGENT {
  LOGOUT = '/nbn-agent/agent-logout'
}

export enum AUTH_API_ROUTES {
  LOGIN = '/oauth/token',
  RESET_PASSWORD = '/customer/v2/reset-password',
  ROOT = '/auth/v1',
  VERIFY_ACCOUNT = '/authentication/v2/tokens',
  VERIFY_EMAIL = '/authentication/v2/verifications/email',
  VERIFY_EMAIL_PASSWORDLESS = '/authentication/v2/passwordless/email'
}

export enum BROADBAND_API_ENDPOINTS {
  CONFIRM_ORDER = '/orchestrator/v2/payment',
  SAVE_ORDER = '/orchestrator/v2/orders'
}

export enum CATALOGUE_API_ROUTES {
  MOBILE_CATALOGUE = '/product/v1/products/mobile',
  MOBILE_DATA_CATALOGUE = '/product/v1/products/mobile-data',
  MOBILE_DATA_PLAN = '/product/v1/products/mobile-data/available-offers',
  MOBILE_PLAN = '/product/v1/products/mobile/available-offers'
}

export enum CATALOGUE_API_ROUTES_V2 {
  MOBILE_OFFERS = '/product/v2/products/mobile/available-offers',
  MOBILE_DATA_OFFERS = '/product/v2/products/mobile-data/available-offers'
}

export enum CHAT_ROUTES {
  AUTHENTICATED_INITIATE = '/livechat/v1/messaging/sessions',
  ANONYMOUS_INITIATE = '/livechat/v1/messaging/sessions/anonymous',
  FEEDBACK_RATING = '/livechat/v1/messaging/sessions/:contactId/feedback',
  FEEDBACK_COMMENT = '/livechat/v1/messaging/sessions/:contactId/feedback/comment',
  QUEUE_POSITION = '/livechat/v1/messaging/sessions/:contactId/position'
}

export enum CUSTOMER_API_ROUTES {
  CASES = '/customer/v1/cases',
  CHANGE_BILLING_ADDRESS = '/customer/v1/accounts/address',
  CHANGE_CONTACT_NUMBER = '/customer/v1/contacts/mobile',
  CUSTOMER = '/customer/v1/customer',
  DOB = '/customer/v1/customer/dob',
  IAF = '/customer/v1/promotions/iaf',
  OTP = '/customer/v1/otp',
  PROMOTIONS = '/customer/v1/promotions/:promotionCode',
  REGISTER = '/customer/v1/register',
  REGISTER_V2 = '/customer/v2/register',
  ROOT = '/customer/v1',
  UNAUTH_MOBILE_CASES = '/customer/v1/cases/anonymous-cases'
}

export const GREEN_CHOICE_ENDPOINT = '/customer/v1/green-categories/:campaignId';

export enum IDENTITY_VERIFICATION_ROUTES {
  TOKEN_EXCHANGE = '/identity-verification/v1/tokens',
  IDV_EVIDENCE = '/identity-verification/v1/idv/evidence',
  IDV_STATUS = '/identity-verification/v1/idv/status'
}

export enum MARKETING_CAMPAIGN_ROUTES {
  SUBMIT_ENTRY = '/campaign/v1/entry'
}

export enum MFA_API_ROUTES {
  ENROL_MOBILE = '/authentication/v2/mfa/enrol/mobile',
  ENROL_EMAIL = '/authentication/v2/mfa/enrol/email',
  VERIFY_MFA_TOKEN = '/authentication/v2/mfa/token'
}

export enum NBN_ORCHESTRATOR_ENDPOINTS {
  ACTIVATE = '/nbn-orchestrator/v1/orders/:orderId/activate',
  AGENT_CHECK_PAYMENT_STATUS = '/nbn-orchestrator/v1/assisted-orders/payment/:paymentToken',
  AGENT_RESEND_PAYMENT_TOKEN = '/nbn-orchestrator/v1/assisted-orders/:orderId/resend',
  AGENT_TERMS_CONFIRM = '/nbn-orchestrator/v1/assisted-orders/:orderId/confirm',
  APPOINTMENT_RESCHEDULE = '/nbn-orchestrator/v1/orders/:serviceId/appointment/reschedule',
  AVAILABLE_SLOTS = '/nbn-orchestrator/v1/orders/:orderId/appointment/available-slots',
  CHANGE_PLAN_DRAFT = '/nbn-orchestrator/v1/services/:serviceId/change-plan',
  CHANGE_PLAN_SUBMIT = '/nbn-orchestrator/v1/services/:serviceId/change-plan/:orderId/submit',
  CONFIRM = '/nbn-orchestrator/v1/orders/:orderId/confirm',
  DELIVERY_ADDRESS = '/nbn-orchestrator/v1/orders/:orderId/delivery-address',
  GET_ORDER = '/nbn-orchestrator/v1/orders/:orderId',
  MODEM = '/nbn-orchestrator/v1/orders/:orderId/modem',
  NDC = '/nbn-orchestrator/v1/orders/:orderId/ndc',
  ORDERS = '/nbn-orchestrator/v1/orders',
  ORDER_VALIDATION = '/nbn-orchestrator/v1/orders/:orderId/validate',
  AVC_VALIDATION = '/nbn-orchestrator/v1/orders/:orderId/service-info',
  PAYMENT = '/nbn-orchestrator/v1/orders/:orderId/payment',
  PROMOTIONS = '/nbn-orchestrator/v1/orders/:orderId/promotions'
}

export enum ORDERS_API_ROUTES {
  ACTIVATE_SIM = '/orchestrator/v1/orders/activate',
  ORDER = '/orchestrator/v1/orders/:orderId',
  ORDERS = '/orchestrator/v1/orders',
  OTP = '/orchestrator/v1/otp',
  PAYMENT = '/orchestrator/v1/payment'
}

export const SEARCH_ENDPOINT = '/multi_search';

export enum SERVICES_API_ENDPOINTS {
  ADDRESS = '/orchestrator/v1/services/:serviceId/address',
  INVOICE = '/orchestrator/v1/services/:serviceId/invoices/:invoiceId/downloads',
  MOBILE_INVOICES = '/orchestrator/v1/services/:serviceId/invoices',
  MOBILE_SERVICE = '/orchestrator/v1/services/:serviceId',
  MAS = '/nbn-orchestrator/v1/mas/:masId',
  NBN_SERVICE = '/nbn-orchestrator/v1/services/:serviceId',
  NBN_DISCONNECT = '/nbn-orchestrator/v1/services/:serviceId/disconnect',
  NBN_INVOICE = '/nbn-orchestrator/v1/services/:serviceId/invoices/:invoiceId/download',
  NBN_INVOICES = '/nbn-orchestrator/v1/services/:serviceId/invoices',
  OUTAGES = '/fixed-service-outage/v1/services',
  MOBILE_PAY_NOW = '/orchestrator/v1/services/:serviceId/payments',
  MOBILE_PAY_NOW_V2 = '/orchestrator/v2/services/:serviceId/payments',
  NBN_PAY_NOW = '/nbn-orchestrator/v1/services/:serviceId/payments',
  PUK = '/orchestrator/v1/services/:serviceId/puk',
  PAYMENT_METHOD = '/orchestrator/v1/services/:serviceId/paymentMethod',
  SERVICES = '/orchestrator/v1/services',
  MOBILE_SERVICES = '/orchestrator/v1/services/mobile',
  NBN_SERVICES = '/nbn-orchestrator/v1/services',
  SIM = '/orchestrator/v1/services/:serviceId/sim',
  AVAILABLE_OFFERS = '/orchestrator/v1/services/:serviceId/offers'
}

export enum SERVICE_HEALTH_API_ROUTES {
  ROOT = '/nbn-assurance/v1/service-health'
}

export enum SUPPORT_API_ROUTES {
  ANONYMOUS_SUPPORT_CASES = '/support/v1/anonymous-cases',
  FBB_CASES = '/support/v1/customer-cases',
  UNAUTH_FBB_CASES = '/support/v1/unauth-customer-cases'
}
